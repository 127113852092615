import {ApplicationController} from '~/app/javascript/controllers/application';

export default class extends ApplicationController {
  connect() {
    this.formId = this.data.get('id')
    this.form = document.getElementById(this.formId)
  }

  submit(event) {
    event.preventDefault()

    $(this.form).submit()
    // this.form.dispatchEvent(new Event('submit', {bubbles: true}))
  }
}
