import {consumer} from './actioncable';

if (window.RailsLegacy && window.RailsEnv === 'development') {
  // Start ActionCable
  let hotwireReload = false;

  window.document.addEventListener('turbo:before-render', () => {
    if (!hotwireReload) {
      return;
    }

    hotwireReload = false;

    // eslint-disable-next-line no-console
    console.log('[LEGACY] Reloading Turbolinks');

    window.Turbolinks.visit(window.location.href, {action: 'replace'});
  });

  const reloadTurboLinks = ({force_reload: forceReload}: any) => {
    const onErrorPage = window.document.title === 'Action Controller: Exception caught';

    if (onErrorPage || forceReload) {
      return;
    }

    hotwireReload = true;
  };

  consumer.subscriptions.create('Hotwire::Livereload::ReloadChannel', {
    received: reloadTurboLinks,
  });
}
