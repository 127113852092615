import {useDebounce} from 'stimulus-use';

import {ApplicationController} from '~/app/javascript/controllers/application';

export default class BranchDropdownController extends ApplicationController {
  static debounces = ['onChange'];

  static targets = ['autocomplete'];

  declare readonly hasAutoCompleteTarget: boolean;
  declare readonly autocompleteTarget: HTMLInputElement;
  declare readonly autocompleteTargets: HTMLInputElement[];

  get inputField(): HTMLInputElement | null {
    return this.autocompleteTarget.querySelector('input[data-action="polaris-text-field#syncValue"]');
  }

  get inputValue(): string {
    return this.inputField?.value ?? '';
  }

  get branchName(): string {
    return this.componentOptions.branchName;
  }

  get polarisList() {
    return this.autocompleteTarget.querySelector('.Polaris-OptionList');
  }

  connect(): void {
    useDebounce(this, {wait: 250});

    this.selectBranchInput();
    this.autocompleteTarget.addEventListener('polaris-autocomplete:change', this.onChange);
    this.inputField?.addEventListener('change', this.onChange);
  }

  disconnect(): void {
    this.inputField?.removeEventListener('change', this.onChange);
    this.autocompleteTarget.removeEventListener('polaris-autocomplete:change', this.onChange);
  }

  onChange = (): void => {
    if (this.inputValue.length < 3) {
      return;
    }

    this.turboStream({
      branchName: this.inputValue,
    });
  };

  selectBranchInput(): void {
    if (!this.branchName) {
      return;
    }

    this.polarisList?.querySelector(`input[value="${this.branchName}"]`)?.parentElement?.click();
  }
}
