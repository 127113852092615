/* eslint-disable no-console */
import {Controller} from '@hotwired/stimulus';

export default class PasswordMaskController extends Controller {
  static targets = ['password', 'toggle'];
  static values = {url: String};

  declare passwordTarget: HTMLElement;
  declare toggleTarget: HTMLElement;
  private isPasswordVisible = false;

  async toggle(event: Event) {
    event.preventDefault();

    if (this.isPasswordVisible) {
      this.passwordTarget.textContent = '********';
      this.toggleTarget.textContent = 'View Password';
    } else {
      try {
        const url = this.toggleTarget.getAttribute('data-password-mask-url-value');
        if (url) {
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            this.passwordTarget.textContent = data.password;
            this.toggleTarget.textContent = ' Hide';
          } else {
            console.error('Failed to fetch password');
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }

    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
