/* eslint-disable no-console */
import {Controller} from '@hotwired/stimulus';

export default class OrderWidgetController extends Controller {
  static targets = ['note'];

  fetchHazardData(lat?: number, lng?: number) {
    const url = '/orders/fetch_hazard_data';
    const data: {[key: string]: any} = {};
    data.lat = lat;
    data.lng = lng;

    fetch(`${url}?${new URLSearchParams(data)}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.error('Failed to fetch hazard data:', response.error);
        } else {
          this.noteTarget.insertAdjacentHTML('afterend', response.html);
        }
      })
      .catch((error) => {
        console.error('AJAX request failed:', error);
      });
  }
}
