import {ApplicationController} from '~/app/javascript/controllers/application';

export default class AutocompleteController extends ApplicationController {
  select = null

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      searchingText: 'Searching...',
      placeholder: 'Please Select',
    })
    this.select.render()
  }
}
