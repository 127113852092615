import {post} from '@rails/request.js';
import {Application} from '@hotwired/stimulus';
import {ApplicationController as StimulusApplicationController} from 'stimulus-use';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;

window.Stimulus = application;

export class ApplicationController extends StimulusApplicationController {
  get componentOptions(): any {
    return JSON.parse(this.data.get('componentOptions') || '{}');
  }

  get controllerName(): string {
    return this.context.controller.identifier;
  }

  turboStream<T>(body: T): Promise<void> {
    const options = {
      ...this.componentOptions,
      ...body,
    };

    return post(`/view_component/${this.controllerName}`, {
      body: {options},
      responseKind: 'turbo-stream',
    });
  }
}

export {application};
