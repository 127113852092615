import {Controller} from '@hotwired/stimulus';

export default class PasswordValidationController extends Controller {
  static targets = ['password', 'passwordConfirmation', 'submitButton', 'errorMessage'];

  passwordTarget!: HTMLInputElement;
  passwordConfirmationTarget!: HTMLInputElement;
  submitButtonTarget!: HTMLButtonElement;
  errorMessageTarget!: HTMLElement;

  connect() {
    this.submitButtonTarget.disabled = true;
  }

  validate() {
    const password = this.passwordTarget.value;
    const passwordConfirmation = this.passwordConfirmationTarget.value;
    let errorMessage = '';

    if (password.length < 6) {
      errorMessage = 'Password is too short (minimum is 6 characters)';
    } else if (password.length > 128) {
      errorMessage = 'Password is too long (maximum is 128 characters)';
    } else if (password !== passwordConfirmation) {
      errorMessage = "Password confirmation doesn't match Password";
    }

    this.errorMessageTarget.textContent = errorMessage;
    this.submitButtonTarget.disabled = errorMessage !== '';
  }
}
