import {ApplicationController} from '~/app/javascript/controllers/application';

export default class extends ApplicationController {
  static targets = [ "yes", "no", "agreedToTerms" ]

  connect() {
  }

  yes(_event: Event) {
    const {noTarget, yesTarget, agreedToTermsTarget} = this

    noTarget.classList.remove('selected')
    yesTarget.classList.add('selected')
    agreedToTermsTarget.value = '1'
  }

  no(_event: Event) {
    const {noTarget, yesTarget, agreedToTermsTarget} = this

    // if data-report-type="Wildfire" has class selected
    if (window.$('[data-report-type="WILDFIRE_ONLY"]').hasClass('selected')) {
      // click data-report-type = Residential
      window.$('[data-report-title="Premium Residential Report"]').click()
    }

    yesTarget.classList.remove('selected')
    noTarget.classList.add('selected')
    agreedToTermsTarget.value = '0'
  }

  getInputs() {
    return [...this.element.getElementsByTagName('input')]
  }
}
