import {ApplicationController} from '~/app/javascript/controllers/application';

export default class extends ApplicationController {
  static targets = ["yes", "no", "agentTable", "overlay"]

  connect() {
    this.userRole = this.data.get('user-role')
    this.requireAgent = this.data.get('require-agent') === 'true'

    if (this.userRole === 'agent') {
      this.overlayTarget.setAttribute('style', 'display:block')
    }

    if (this.requireAgent) {
      this.yes(new Event('default'))
      this.enableForm()
    } else {
      this.no(new Event('default'))
      this.disableForm()
    }
  }

  updateForm(event) {
    event.preventDefault()
    // TODO: use stimulus targets
    const jsElements = Array.from(this.element.getElementsByClassName('js-show-when-adding-agent'))
    const addAgentOption = document.getElementById('order_agent_attributes_id_0').parentElement

    // Find the checked radio input
    const checkedRadio = this.element.querySelector('input[name="order[agent_attributes][id]"]:checked')

    if (checkedRadio && checkedRadio.value == '0') {
      // entering new agent
      this.setRequiredAttributes(true)
      jsElements.forEach((el) =>
        el.classList.remove('hidden')
      )
      addAgentOption.classList.add('hidden')
    } else {
      // using saved agent
      this.setRequiredAttributes(false)
      addAgentOption.classList.remove('hidden')
      jsElements.forEach((el) =>
        el.classList.add('hidden')
      )
    }
  }

  yes(event) {
    event.preventDefault()

    const {noTarget, yesTarget, agentTableTarget} = this

    this.enableForm()

    noTarget.classList.remove('selected')
    yesTarget.classList.add('selected')
    agentTableTarget.classList.remove('hidden')

    this.updateForm(event)
  }

  no(event) {
    event.preventDefault()

    const {noTarget, yesTarget, agentTableTarget} = this

    this.disableForm()

    yesTarget.classList.remove('selected')
    noTarget.classList.add('selected')
    agentTableTarget.classList.add('hidden')

    this.setRequiredAttributes(false)
  }

  disableForm() {
    const inputs = this.getInputs()

    inputs.forEach((input) =>
      input.setAttribute('disabled', 'disabled')
    )
  }

  enableForm() {
    const inputs = this.getInputs()

    inputs.forEach((input) =>
      input.removeAttribute('disabled')
    )
  }

  getInputs() {
    return [...this.element.getElementsByTagName('input')]
  }

  setRequiredAttributes(isRequired: boolean) {
    const nameInput = document.getElementById('order_agent_attributes_name')

    if (isRequired && this.requireAgent) {
      nameInput.setAttribute('required', 'required')
    } else {
      nameInput.removeAttribute('required')
    }
  }
}
