import {useMutation, useTargetMutation} from 'stimulus-use';

import {ApplicationController} from '~/app/javascript/controllers/application';

export default class RepDropdownController extends ApplicationController {
  static targets = ['autocomplete', 'selectedRepId'];

  static values = {
    options: Object,
  };

  selectedRepId?: number;

  declare readonly hasAutocompleteTarget: boolean;
  declare readonly autocompleteTarget: HTMLInputElement;
  declare readonly autocompleteTargets: HTMLInputElement[];
  declare readonly hasSelectedRepiIdTarget: boolean;
  declare readonly selectedRepIdTarget: HTMLInputElement;
  declare readonly selectedRepIdTargets: HTMLInputElement[];

  connect(): void {
    useMutation(this, {attributes: true, subtree: true});
    useTargetMutation(this, {targets: ['selectedRepId']});
  }

  mutate(entries: any) {
    for (const mutation of entries) {
      if (mutation.target !== this.selectedRepId && mutation.attributeName !== 'style') {
        return;
      }

      const styles = mutation.target.getAttribute('style');

      if (styles?.includes('display: block')) {
        this.selectRep();
      }
    }
  }

  selectRep() {
    this.selectedRepId = parseInt(this.selectedRepIdTarget.value, 10);
    this.selectedRepIdInput?.click();
  }

  selectedRepIdTargetAdded(element: HTMLInputElement): void {
    this.selectedRepId = parseInt(element.value, 10);
    this.selectedRepIdInput?.click();
  }

  get polarisList() {
    return this.autocompleteTarget.querySelector('.Polaris-OptionList');
  }

  get selectedRepIdInput(): HTMLInputElement | null | undefined {
    return this.polarisList?.querySelector(`input[value="${this.selectedRepId}"]`);
  }
}
